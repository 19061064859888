$primary: #f48120;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "dabb";
  src: url(../fonts/dabb.ttf) format("truetype");
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: dabb, PingFang SC, Open Sans, Hiragino Sans GB, Heiti SC,
    Microsoft YaHei, WenQuanYi Micro Hei, monospace, serif;

  background-color: #f8f9fa;
}

.code-input {
  margin: auto;
}

::selection {
  color: #fff;
  background: $primary;
}
